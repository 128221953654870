import cat from "@/assets/locales/cat.json";
import cs from "@/assets/locales/cs.json";
import de from "@/assets/locales/de.json";
import en from "@/assets/locales/en.json";
import minion from "@/assets/locales/minion.json";
import pirate from "@/assets/locales/pirate.json";
import pl from "@/assets/locales/pl.json";
import sl from "@/assets/locales/sl.json";
import uk from "@/assets/locales/uk.json";
import uwu from "@/assets/locales/uwu.json";

export const locales = {
  en,
  cs,
  de,
  pl,
  pirate,
  cat,
  uwu,
  minion,
  uk,
  sl,
};

export type Locales = keyof typeof locales;
